<template>
	<div class="contest">
		<div class="contest-cover">
			<div class="contest-cover-pic">
				<div
					class="image-cover-wrap"
					:style="'/img/landing/ambassador/landing-cover-1.jpg' | bgrImageCover"
				/>
			</div>
			<div class="contest-cover-content">
				<div class="content">
					<div class="grid">
						<div class="box">
							<Breadcrumb :page-name="'Cтуденты – старшеклассникам'" />
							<h3 class="page-title">
								Cтуденты – <br>cтаршеклассникам
							</h3>
							<div class="text">
								<p>
									Стань наставником и попробуй себя в деле: <br>
									помоги школьникам определиться с профессией <br>
									через развитие их проектных идей
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<section class="section-simple wow">
			<div class="content">
				<div class="main-button-group">
					<div class="grid">
						<div class="box">
							<button
								class="main-button gray"
								@click="$scrollTo($refs.students)"
							>
								<span class="main-button-text">Студентам</span>
							</button>
						</div>
						<div class="box">
							<button
								class="main-button blue"
								@click="$scrollTo($refs.schoolers)"
							>
								<span class="main-button-text">Школьникам</span>
							</button>
						</div>
						<div class="box">
							<button
								class="main-button orange"
								@click="$scrollTo($refs.school)"
							>
								<span class="main-button-text">Школам</span>
							</button>
						</div>
					</div>
				</div>
			</div>
		</section>

		<section class="section-simple light wow">
			<div class="content">
				<h3 class="block-title">
					Это программа для вас, если вы:
				</h3>
				<div class="text md dark">
					<ul>
						<li>Студент вуза или колледжа и хотите научиться быть наставником</li>
						<li>
							Сами страдали при выборе профессии и вуза и хотите помочь нынешним школьникам определиться с
							будущим
						</li>
						<li>
							Хотите получить поддержку наставника из актива АФК «Система», который поделится опытом и
							поможет вам вырасти
						</li>
						<li>Хотите узнать, как искать работу, и подготовиться к собеседованиям</li>
						<li>
							Хотите прокачать гибкие навыки, которых, по словам работодателей, не хватает почти всем
							выпускникам
						</li>
						<li>Нацелены пройти стажировку в ведущих компаниях</li>
					</ul>
				</div>
			</div>
		</section>

		<section
			ref="students"
			class="section-simple wow"
		>
			<div class="content">
				<div class="about-block">
					<h3 class="block-title">
						Принять участие
					</h3>
					<div class="text md dark">
						<p>Заявки на программу принимаются до 12 сентября 2021 года</p>
					</div>
					<br>
					<div class="center">
						<button
							id="participateAmbsd"
							class="main-button blue"
							@click="modalOpen('ambsdr-2', true)"
						>
							<span class="main-button-text">Принять участие</span>
							<span class="main-button-icon">
								<img
									:src="'/img/icons/icon-user-plus-white.svg' | prodSrcImage"
									alt=""
								>
							</span>
						</button>
					</div>
				</div>
			</div>
		</section>

		<section class="section-simple wow light">
			<div class="content">
				<div class="about-block">
					<h3 class="block-title">
						Короткий путь в Амбассадорство
					</h3>
					<div class="text md dark">
						<p>
							Трек «Студенты – старшеклассникам» проходит в рамках программы «Амбассадоры «Лифта в
							будущее», поэтому вы станете частью основной программы, присоединитесь к активному
							сообществу из 245 амбассадоров, которые уже с нами, получите доступ к полезным материалам,
							сможете принимать участие в мероприятиях Благотворительного фонда «Система», организатора
							программы. И самое главное - будете работать над проектами вместе со школьниками в системе
							Реактор, партнере нашей программы.
						</p>
						<p>
							Опционально для 30 лучших будет предложен отдельный трек, который позволяет принять участие в
							качестве модераторов программ мобильных лабораторий «Лифт в будущее» в разных городах
							России, в том числе на площадках ВДЦ Артек, Океан.
						</p>
					</div>
					<br>
					<div class="center">
						<a
							:href="'/ambassador/amba_program.pdf' | prodSrcImage"
							target="_blank"
							class="main-button gray"
						>
							<span class="main-button-text">Положение <br>о программе</span>
							<span class="main-button-icon">
								<img
									:src="'/img/icons/icon-landing-document.svg' | prodSrcImage"
									alt=""
								>
							</span>
						</a>
					</div>
				</div>
			</div>
		</section>

		<section class="section-simple wow">
			<div class="content">
				<h3 class="block-title">
					Какие возможности открывает проект?
				</h3>
				<div class="jury-group">
					<div class="grid">
						<div class="box">
							<div class="jury">
								<img
									:src="'/img/landing/ambassador/feature-styled-4.png' | prodSrcImage"
									alt=""
									class="jury-avatar"
								>
								<div class="jury-content">
									<div class="jury-job">
										Опыт проектного наставничества по реальным задачам индустрии
									</div>
								</div>
							</div>
						</div>
						<div class="box">
							<div class="jury">
								<img
									:src="'/img/landing/ambassador/feature-styled-7.png' | prodSrcImage"
									alt=""
									class="jury-avatar"
								>
								<div class="jury-content">
									<div class="jury-job">
										Обучение наставничеству и soft skills
									</div>
								</div>
							</div>
						</div>
						<div class="box">
							<div class="jury">
								<img
									:src="'/img/landing/ambassador/feature-styled-9.png' | prodSrcImage"
									alt=""
									class="jury-avatar"
								>
								<div class="jury-content">
									<div class="jury-job">
										Менторская поддержка от экспертов
									</div>
								</div>
							</div>
						</div>
						<div class="box">
							<div class="jury">
								<img
									:src="'/img/landing/ambassador/feature-styled-8.png' | prodSrcImage"
									alt=""
									class="jury-avatar"
								>
								<div class="jury-content">
									<div class="jury-job">
										Сертификаты о прохождении обучения
									</div>
								</div>
							</div>
						</div>
						<div class="box">
							<div class="jury">
								<img
									:src="'/img/landing/ambassador/feature-styled-5.png' | prodSrcImage"
									alt=""
									class="jury-avatar"
								>
								<div class="jury-content">
									<div class="jury-job">
										Классный мерч и подарки победителям
									</div>
								</div>
							</div>
						</div>
						<div class="box">
							<div class="jury">
								<img
									:src="'/img/landing/ambassador/feature-styled-3.png' | prodSrcImage"
									alt=""
									class="jury-avatar"
								>
								<div class="jury-content">
									<div class="jury-job">
										Стажировки в лучших компаниях
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>

		<section class="section-simple light wow">
			<div class="content">
				<div class="jury-block toggle-block">
					<div class="section-bar">
						<div class="grid">
							<div class="box">
								<h3 class="block-title">
									Борд наставников
								</h3>
							</div>
							<div class="box">
								<button
									v-if="textMentorsButton === 'Показать наставников'"
									id="showMentors"
									class="button-hollow"
									:class="{ 'active': isShowMentors }"
									@click="viewMentors"
								>
									{{ textMentorsButton }}
								</button>
								<button
									v-else
									class="button-hollow"
									:class="{ 'active': isShowMentors }"
									@click="viewMentors"
								>
									{{ textMentorsButton }}
								</button>
							</div>
						</div>
					</div>
					<div
						v-if="isShowMentors"
						class="jury-group"
					>
						<div
							v-if="!mentors"
							class="preloader sm"
							style="margin-top: 50px"
						/>
						<div
							v-else
							class="grid"
						>
							<div
								v-for="item in mentors"
								:key="item.id"
								class="box"
							>
								<div class="jury">
									<div
										class="jury-avatar"
										:style="item.image | bgrDynamicImageCover"
									/>
									<div class="jury-content">
										<div class="jury-name">
											{{ item.name }}
										</div>
										<div class="jury-job">
											{{ item.position }}
										</div>
										<a
											href="#"
											class="link-dashed"
											@click.stop.prevent="modalOpen('mentor', item)"
										>Подробнее</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>

		<section class="section-simple wow">
			<div class="content">
				<h3 class="block-title">
					Этапы программы
				</h3>
				<div class="stages-block">
					<div class="stages-line" />
					<div class="stages">
						<div class="stage active">
							<div class="stage-index">
								1 этап
							</div>
							<div class="stage-title">
								Набор на программу
							</div>
							<div class="stage-date">
								до 12 сентября
							</div>
						</div>
						<div class="stage">
							<div class="stage-index">
								2 этап
							</div>
							<div class="stage-title">
								Образовательный интенсив для модераторов программ мобильных
								лабораторий «Лифт в будущее» (опционально)
							</div>
							<div class="stage-date">
								с 06 по 10 сентября
							</div>
						</div>
						<div class="stage">
							<div class="stage-index">
								3 этап
							</div>
							<div class="stage-title">
								Обучение теории наставничества и проектной деятельности, знакомство
								с наставниками от активов АФК и Реактора
							</div>
							<div class="stage-date">
								с 13 по 19 сентября
							</div>
						</div>
						<div class="stage">
							<div class="stage-index">
								4 этап
							</div>
							<div class="stage-title">
								Партнерские мероприятия Фонда в разных городах России
								(опционально)
							</div>
							<div class="stage-date">
								с 10 сентября по 31 декабря
							</div>
						</div>
						<div class="stage">
							<div class="stage-index">
								5 этап
							</div>
							<div class="stage-title">
								Знакомство с подопечными школьниками, формирование команд,
								презентация и выбор кейсов от активов АФК
							</div>
							<div class="stage-date">
								с 20 по 26 сентября
							</div>
						</div>
						<div class="stage">
							<div class="stage-index">
								6 этап
							</div>
							<div class="stage-title">
								Наставничество школьных команд в ходе работы над выбранным
								проектом, супервизия наставников от экспертов АФК и Реактора
							</div>
							<div class="stage-date">
								с 27 сентября по 5 декабря
							</div>
						</div>
						<div class="stage">
							<div class="stage-index">
								7 этап
							</div>
							<div class="stage-title">
								Подготовка проектов к защите
							</div>
							<div class="stage-date">
								с 6 по 12 декабря
							</div>
						</div>
						<div class="stage">
							<div class="stage-index">
								8 этап
							</div>
							<div class="stage-title">
								Защита итоговых проектов, награждение лучших наставников и команд
							</div>
							<div class="stage-date">
								13 декабря
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>

		<section class="section-simple light wow">
			<div class="content">
				<h3 class="block-title">
					Содержание образовательной программы
				</h3>
				<br>
				<h3 class="block-title sm">
					Наставничество <br>
					<small><b>Вы пройдете курс и примете участие в вебинарах по темам</b></small>
				</h3>
				<div class="nomination-wrap">
					<div class="nomination-item top">
						<img
							:src="'/img/landing/ambassador/program-2.png' | prodSrcImage"
							alt=""
							class="nomination-pic"
						>
						<div class="nomination-text text dark sm">
							<ul>
								<li>Что такое проект?</li>
								<li>Что может стать основой проекта?</li>
								<li>Как сформулировать цель проекта?</li>
								<li>Для кого и с кем делается проект?</li>
								<li>Как спланировать работы по проекту?</li>
								<li>Что нужно для реализации проекта?</li>
								<li>Проектное наставничество и работа с командой?</li>
							</ul>
						</div>
					</div>
				</div>
				<h3 class="block-title sm">
					Управление командой <br>
					<small><b>Мы подготовили для вас серию интерактивных курсов по менеджменту</b></small>
				</h3>
				<div class="nomination-wrap">
					<div class="nomination-item top">
						<img
							:src="'/img/landing/ambassador/program-1.png' | prodSrcImage"
							alt=""
							class="nomination-pic"
						>
						<div class="nomination-text text dark sm">
							<ul>
								<li>Работа с возражениями</li>
								<li>Как мотивировать коллег?</li>
								<li>Как давать своевременную и развивающую и обратную связь?</li>
								<li>Как адаптировать свой стиль руководства к разным ситуациям?</li>
								<li>Как контролировать сотрудников и не перегнуть палку?</li>
								<li>Как корректно ставить задачи и мотивировать на их выполнение?</li>
								<li>Как делегировать задачи коллегам и подчиненным?</li>
								<li>Лайфхаки работы в команде</li>
							</ul>
						</div>
					</div>
				</div>
				<h3 class="block-title sm">
					Коммуникации <br>
					<small><b>Дополнительные онлайн курсы для тех, кто хочет улучшить навыки общения и
						выступления</b></small>
				</h3>
				<div class="nomination-wrap">
					<div class="nomination-item top">
						<img
							:src="'/img/landing/ambassador/program-3.png' | prodSrcImage"
							alt=""
							class="nomination-pic"
						>
						<div class="nomination-text text dark sm">
							<ul>
								<li>Публичные выступления</li>
								<li>Презентации</li>
								<li>Переговоры</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</section>

		<section class="section-simple simple wow">
			<div class="content">
				<h3 class="block-title">
					Вопросы и ответы
				</h3>
				<div class="faq-group">
					<label
						class="faq-item"
						:class="{ active: faqGroup.includes('1') }"
					>
						<input
							v-model="faqGroup"
							class="faq-checkbox"
							type="checkbox"
							value="1"
						>
						<div class="faq-header">
							<h4 class="faq-title">Какая миссия у амбассадорской программы &laquo;Лифта в
								будущее&raquo;?</h4>
						</div>
						<div
							class="faq-body"
							:class="{ show: faqGroup.includes('1') }"
						>
							<div class="text dark md">
								<p>Мы создаем непрерывную траекторию школа - вуз - компания, чтобы помочь талантливым
									молодым ребятам со всей России с профориентацией, развитием надпрофессиональных
									навыков, созданием резюме, портфолио и обретением опыта работы. Амбассадоры &laquo;Лифта
									в будущее&raquo; учатся у лучших экспертов, проходят коуч-сессии, обретают опыт
									реализации своих soft skills, а затем в роли наставников помогают школьникам с
									профориентацией и планированием будущего, а также преодолением сложнейшего этапа
									поступления в вузы и колледжи.</p>
							</div>
						</div>
					</label>
					<label
						class="faq-item"
						:class="{ active: faqGroup.includes('2') }"
					>
						<input
							v-model="faqGroup"
							class="faq-checkbox"
							type="checkbox"
							value="2"
						>
						<div class="faq-header">
							<h4 class="faq-title">Кто может стать амбассадором &laquo;Лифта в будущее&raquo;?</h4>
						</div>
						<div
							class="faq-body"
							:class="{ show: faqGroup.includes('2') }"
						>
							<div class="text dark md">
								<p>Студент российского вуза или ссуза.</p>
							</div>
						</div>
					</label>
					<label
						class="faq-item"
						:class="{ active: faqGroup.includes('3') }"
					>
						<input
							v-model="faqGroup"
							class="faq-checkbox"
							type="checkbox"
							value="3"
						>
						<div class="faq-header">
							<h4 class="faq-title">Что делает амбассадор?</h4>
						</div>
						<div
							class="faq-body"
							:class="{ show: faqGroup.includes('3') }"
						>
							<div class="text dark md">
								<ul>
									<li>начинает карьеру в компаниях АФК &laquo;Система&raquo;,</li>
									<li>наращивает свою экспертизу в области гибких навыков при поддержке экспертов и
										наставников,
									</li>
									<li>получает опыт наставничества школьных команд,</li>
									<li>организует мероприятия,</li>
									<li>получает диплом и бонусы за активность в программе.</li>
								</ul>
							</div>
						</div>
					</label>
					<label
						class="faq-item"
						:class="{ active: faqGroup.includes('4') }"
					>
						<input
							v-model="faqGroup"
							class="faq-checkbox"
							type="checkbox"
							value="4"
						>
						<div class="faq-header">
							<h4 class="faq-title">Как проходит отбор амбассадоров?</h4>
						</div>
						<div
							class="faq-body"
							:class="{ show: faqGroup.includes('4') }"
						>
							<div class="text dark md">
								<p>Мы смотрим в первую очередь на мотивацию развиваться самостоятельно и помогать другим
									двигаться вперед. Первый отбор проходит при подаче заявки на программу, когда вы
									оставляете свое мотивационное письмо. Второй отбор проходит в формате
									видеоинтервью.</p>
							</div>
						</div>
					</label>
					<label
						class="faq-item"
						:class="{ active: faqGroup.includes('5') }"
					>
						<input
							v-model="faqGroup"
							class="faq-checkbox"
							type="checkbox"
							value="5"
						>
						<div class="faq-header">
							<h4 class="faq-title">Оплачивается ли деятельность амбассадоров?</h4>
						</div>
						<div
							class="faq-body"
							:class="{ show: faqGroup.includes('5') }"
						>
							<div class="text dark md">
								<p>Нет, но открываются большие возможности для старта карьеры, обучения самым
									востребованным в компаниях надпрофессиональным навыкам и приобретения опыта
									наставничества, который высоко ценится работодателями.</p>
							</div>
						</div>
					</label>
					<label
						class="faq-item"
						:class="{ active: faqGroup.includes('6') }"
					>
						<input
							v-model="faqGroup"
							class="faq-checkbox"
							type="checkbox"
							value="6"
						>
						<div class="faq-header">
							<h4 class="faq-title">Можно ли участвовать в программе из своего города?</h4>
						</div>
						<div
							class="faq-body"
							:class="{ show: faqGroup.includes('6') }"
						>
							<div class="text dark md">
								<p>Да, обучение и общение с наставниками будет проходить дистанционно, школьные команды
									для выполнения проектов и презентаций будут подбираться в вашем городе. Стажировки
									для прошедших отбор будут предложены в том же городе или близлежащих.</p>
							</div>
						</div>
					</label>
					<label
						class="faq-item"
						:class="{ active: faqGroup.includes('7') }"
					>
						<input
							v-model="faqGroup"
							class="faq-checkbox"
							type="checkbox"
							value="7"
						>
						<div class="faq-header">
							<h4 class="faq-title">Сколько времени будет занимать участие в программе?</h4>
						</div>
						<div
							class="faq-body"
							:class="{ show: faqGroup.includes('7') }"
						>
							<div class="text dark md">
								<p>С сентября по декабрь, во время выполнения роли наставника проектной работы со
									школьниками, нагрузка будет 1-2 часа в неделю.</p>
							</div>
						</div>
					</label>
					<label
						class="faq-item"
						:class="{ active: faqGroup.includes('8') }"
					>
						<input
							v-model="faqGroup"
							class="faq-checkbox"
							type="checkbox"
							value="8"
						>
						<div class="faq-header">
							<h4 class="faq-title">Стану ли я сотрудником компаний группы АФК &laquo;Система&raquo; после
								прохождения программы?</h4>
						</div>
						<div
							class="faq-body"
							:class="{ show: faqGroup.includes('8') }"
						>
							<div class="text dark md">
								<p>Программа – это fast track к трудоустройству в компаниях АФК &laquo;Система&raquo;
									для тех, кто успешно проявит себя. Амбассадоры будут активно взаимодействовать с
									экспертами-наставниками и HR компаний, получат возможность пройти стажировку в
									компаниях, что значительно повышает шансы на трудоустройство.</p>
							</div>
						</div>
					</label>
				</div>
				<br>
				<div class="center">
					<button
						id="participateAmbsd"
						class="button blue"
						@click="modalOpen('ambsdr-2', true)"
					>
						Принять
						участие
					</button>
				</div>
			</div>
		</section>

		<section class="section-simple wow light">
			<div class="content">
				<div class="about-block">
					<h3 class="block-title">
						Короткий путь в Амбассадорство
					</h3>
					<div class="text md dark">
						<p>
							Для школьника программа «Студенты – старшеклассникам» – это возможность найти себя в
							современном мире профессий. Заявку на участие может подать и сам школьник, и представитель
							школы. Для этого ниже на этом сайте можно найти две специальные формы на выбор.
						</p>
						<p>
							К концу сентября школьники должны сформировать команды до 5 человек каждая. От одного класса
							и от одной школы может быть неограниченное количество команд. В конце сентября эксперты из
							активов АФК «Система» проведут презентации кейсов, из которых можно будет выбрать наиболее
							интересный и сделать в рамках этого кейса свой проект. В помощь к каждой школьной команде
							прикрепится студент-наставник, он будет еженедельно проводить встречи очно или онлайн, чтобы
							структурировать и направить работу над проектом. Также школьникам будут предложены
							специальные обучающие материалы по проектной деятельности, чтобы научиться всему самому
							важному в этом непростом деле.
						</p>
						<p>
							Финал конкурса пройдет в середине декабря, лучшие проекты будут представлены к
							онлайн-защите. Победители программы получат подарки от организаторов. Более подробная
							информация и план работ будут направлены на электронные почты отобранных на программу
							участников после завершения сроков набора на программу.
						</p>
					</div>
				</div>
			</div>
		</section>

		<section
			ref="schoolers"
			class="section-simple wow"
		>
			<div class="content">
				<div class="contest-form-block">
					<h4 class="block-title">
						Если ты школьник
					</h4>
					<form
						class="form"
						@submit.prevent="sendPartner"
					>
						<div class="grid">
							<div class="box">
								<div class="field-wrap">
									<label class="field-label">
										<input
											v-model.trim="$v.partner.full_name.$model"
											type="text"
											class="field"
											placeholder="ФИО"
											style="text-transform: capitalize"
										>
										<div class="label">ФИО</div>
										<span
											v-if="$v.partner.full_name.$error"
											class="help-block form-error"
										>
											{{
												!$v.partner.full_name.required ? 'Это обязательное поле' : $v.partner.full_name.alphaSpace ? 'Только буквы, пробел и дефис' : 'Введите корректное ФИО'
											}}
										</span>
									</label>
								</div>
							</div>
							<div class="box">
								<div class="field-wrap">
									<label class="field-label">
										<input
											v-model.trim="$v.partner.email.$model"
											type="email"
											class="field"
											placeholder="Email"
										>
										<div class="label">Email</div>
										<span
											v-if="$v.partner.email.$error"
											class="help-block form-error"
										>
											{{
												!$v.partner.email.required ? 'Это обязательное поле' : !$v.partner.email.email ? 'Введите корректный e-mail' : 'Ошибка поля'
											}}
										</span>
									</label>
								</div>
							</div>
							<div class="box">
								<div class="field-wrap">
									<label class="field-label">
										<input
											v-model="$v.partner.phone.$model"
											v-mask="'+7(###)###-##-##'"
											type="text"
											class="field"
											placeholder="Телефон"
										>
										<div class="label">Телефон</div>
										<span
											v-if="$v.partner.phone.$error"
											class="help-block form-error"
										>
											{{
												!$v.partner.phone.required ? 'Это обязательное поле' : !$v.partner.phone.Phone ? 'Введите корректный номер телефона' : 'Ошибка поля'
											}}
										</span>
									</label>
								</div>
							</div>
							<div class="box">
								<div class="field-wrap">
									<label class="field-label">
										<input
											v-model="partner.city"
											type="text"
											class="field"
											placeholder="Город"
										>
										<div class="label">Город</div>
										<span
											v-if="$v.partner.city.$error"
											class="help-block form-error"
										>Это обязательное поле
										</span>
									</label>
								</div>
							</div>
							<div class="box">
								<div class="field-wrap">
									<label class="field-label">
										<input
											v-model.trim="$v.partner.school.$model"
											type="text"
											class="field"
											placeholder="Школа"
										>
										<div class="label">Школа</div>
										<span
											v-if="$v.partner.school.$error"
											class="help-block form-error"
										>Это обязательное поле</span>
									</label>
								</div>
							</div>
						</div>
						<div class="field-wrap sm">
							<label class="checkbox-filter">
								<input
									v-model="$v.partner.isPersonal.$model"
									type="checkbox"
									@change="$v.partner.isPersonal.$touch()"
								>
								<span class="info">Соглашаюсь с условиями обработки <a
									href="/privacy-policy"
									target="_blank"
								>персональных данных</a></span>
								<div
									v-if="$v.partner.isPersonal.$error"
									class="help-block form-error"
								>Необходимо
									подтверждение
								</div>
							</label>
						</div>
						<div class="field-wrap">
							<label class="checkbox-filter">
								<input
									v-model="$v.partner.isAgree.$model"
									type="checkbox"
									@change="$v.partner.isAgree.$touch()"
								>
								<span class="info">Соглашаюсь с условиями <a
									href="/agreement"
									target="_blank"
								>пользовательского соглашения</a></span>
								<div
									v-if="$v.partner.isAgree.$error"
									class="help-block form-error"
								>Необходимо
									подтверждение
								</div>
							</label>
						</div>
						<button
							v-if="!isSended"
							id="btnAmbsdrSend"
							class="button orange lg btnRegistration"
						>
							Отправить
						</button>
						<div
							v-else
							class="button orange lg button-preloader"
						>
							<div class="button-preloader-icons">
								<div class="button-preloader-icon" />
								<div class="button-preloader-icon" />
								<div class="button-preloader-icon" />
							</div>
						</div>
					</form>
				</div>
			</div>
		</section>

		<section class="section-simple light wow">
			<div class="content">
				<div class="partner-program-block">
					<div class="section-bar">
						<div class="grid">
							<div class="box">
								<h3 class="block-title">
									Партнер программы
								</h3>
							</div>
							<div class="box">
								<a
									href="https://reactor.su/ru"
									target="_blank"
								>
									<img
										:src="'/img/landing/ambassador/partner-reaktor.svg' | prodSrcImage"
										alt=""
										class="partner-program-logo"
									>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>

		<section
			ref="school"
			class="section-simple wow"
		>
			<div class="content">
				<div class="contest-form-block">
					<h4 class="block-title">
						Школам
					</h4>
					<div class="text dark sm">
						<p>
							Если вы представитель школы и хотите присоединиться к проекту, оставьте заявку с помощью
							формы ниже:
						</p>
					</div>
					<br>
					<form
						class="form"
						@submit.prevent="sendPartnerForSchool"
					>
						<div class="grid">
							<div class="box">
								<div class="field-wrap">
									<label class="field-label">
										<input
											v-model.trim="$v.forSchool.full_name.$model"
											type="text"
											class="field"
											placeholder="ФИО"
											style="text-transform: capitalize"
										>
										<div class="label">ФИО</div>
										<span
											v-if="$v.forSchool.full_name.$error"
											class="help-block form-error"
										>
											{{
												!$v.forSchool.full_name.required ? 'Это обязательное поле' : $v.forSchool.full_name.alphaSpace ? 'Только буквы, пробел и дефис' : 'Введите корректное ФИО'
											}}
										</span>
									</label>
								</div>
							</div>
							<div class="box">
								<div class="field-wrap">
									<label class="field-label">
										<input
											v-model.trim="$v.forSchool.email.$model"
											type="email"
											class="field"
											placeholder="Email"
										>
										<div class="label">Email</div>
										<span
											v-if="$v.forSchool.email.$error"
											class="help-block form-error"
										>
											{{
												!$v.forSchool.email.required ? 'Это обязательное поле' : !$v.forSchool.email.email ? 'Введите корректный e-mail' : 'Ошибка поля'
											}}
										</span>
									</label>
								</div>
							</div>
							<div class="box">
								<div class="field-wrap">
									<label class="field-label">
										<input
											v-model="$v.forSchool.phone.$model"
											v-mask="'+7(###)###-##-##'"
											type="text"
											class="field"
											placeholder="Телефон"
										>
										<div class="label">Телефон</div>
										<span
											v-if="$v.forSchool.phone.$error"
											class="help-block form-error"
										>
											{{
												!$v.forSchool.phone.required ? 'Это обязательное поле' : !$v.forSchool.phone.Phone ? 'Введите корректный номер телефона' : 'Ошибка поля'
											}}
										</span>
									</label>
								</div>
							</div>
							<div class="box">
								<div class="field-wrap">
									<label class="field-label">
										<input
											v-model="forSchool.city"
											type="text"
											class="field"
											placeholder="Город"
										>
										<div class="label">Город</div>
										<span
											v-if="$v.forSchool.city.$error"
											class="help-block form-error"
										>Это обязательное поле
										</span>
									</label>
								</div>
							</div>
							<div class="box">
								<div class="field-wrap">
									<label class="field-label">
										<input
											v-model.trim="$v.forSchool.school.$model"
											type="text"
											class="field"
											placeholder="Школа"
										>
										<div class="label">Школа</div>
										<span
											v-if="$v.forSchool.school.$error"
											class="help-block form-error"
										>Это обязательное поле</span>
									</label>
								</div>
							</div>
							<div class="box">
								<div class="field-wrap">
									<label class="field-label">
										<input
											v-model.trim="$v.forSchool.class_list.$model"
											type="text"
											class="field"
											placeholder="Список классов"
										>
										<div class="label">Список классов</div>
										<span
											v-if="$v.forSchool.class_list.$error"
											class="help-block form-error"
										>Это обязательное поле</span>
									</label>
									<!--<label class="field-label">-->
									<!--    <input type="text" class="field" placeholder="Какие классы готовы принимать участие">-->
									<!--    <div class="label">Какие классы готовы принимать участие</div>-->
									<!--</label>-->
								</div>
							</div>
							<div class="box">
								<div class="field-wrap">
									<label class="field-label">
										<input
											v-model.trim="$v.forSchool.pupils.$model"
											type="text"
											class="field"
											placeholder="Число школьников"
										>
										<div class="label">Число школьников</div>
										<span
											v-if="$v.forSchool.pupils.$error"
											class="help-block form-error"
										>Это обязательное поле</span>
									</label>
									<!--<label class="field-label">-->
									<!--    <input type="text" class="field" placeholder="Количество школьников готовых принять участие">-->
									<!--    <div class="label">Количество школьников готовых принять участие</div>-->
									<!--</label>-->
								</div>
							</div>
						</div>
						<div class="field-wrap sm">
							<label class="checkbox-filter">
								<input
									v-model="$v.forSchool.isPersonal.$model"
									type="checkbox"
									@change="$v.forSchool.isPersonal.$touch()"
								>
								<span class="info">Соглашаюсь с условиями обработки <a
									href="/privacy-policy"
									target="_blank"
								>персональных данных</a></span>
								<div
									v-if="$v.forSchool.isPersonal.$error"
									class="help-block form-error"
								>Необходимо
									подтверждение
								</div>
							</label>
						</div>
						<div class="field-wrap">
							<label class="checkbox-filter">
								<input
									v-model="$v.forSchool.isAgree.$model"
									type="checkbox"
									@change="$v.forSchool.isAgree.$touch()"
								>
								<span class="info">Соглашаюсь с условиями <a
									href="/agreement"
									target="_blank"
								>пользовательского соглашения</a></span>
								<div
									v-if="$v.forSchool.isAgree.$error"
									class="help-block form-error"
								>Необходимо
									подтверждение
								</div>
							</label>
						</div>
						<button
							v-if="!isSended"
							id="btnAmbsdr_4"
							class="button orange lg btnRegistration"
						>
							Отправить
						</button>
						<div
							v-else
							class="button orange lg button-preloader"
						>
							<div class="button-preloader-icons">
								<div class="button-preloader-icon" />
								<div class="button-preloader-icon" />
								<div class="button-preloader-icon" />
							</div>
						</div>
					</form>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb';
import Cnt from '@/api/contest';
import {email, required, sameAs} from 'vuelidate/lib/validators';
import {alphaSpace, Phone} from '@/assets/scripts/custom_validators';

export default {
    name: 'AmbassadorsForStudents',
    components: {
        Breadcrumb
    },
    data: () => ({
        partner: {
            full_name: '',
            city: '',
            phone: '',
            email: '',
            school: '',
            isPersonal: false,
            isAgree: false,
        },
        forSchool: {
            full_name: '',
            city: '',
            phone: '',
            email: '',
            school: '',
            pupils: '',
            class_list: '',
            isPersonal: false,
            isAgree: false,
        },
        mentors: null,
        isShowMentors: false,
        faqGroup: [],
        isCityClear: false,
        isForSchoolCityClear: false,
        isSended: false
    }),
    validations: {
        partner: {
            full_name: {required, alphaSpace},
            email: {required, email},
            phone: {required, Phone},
            city: {required},
            school: {required},
            isPersonal: {
                sameAs: sameAs(() => true)
            },
            isAgree: {
                sameAs: sameAs(() => true)
            }
        },
        forSchool: {
            full_name: {required, alphaSpace},
            email: {required, email},
            phone: {required, Phone},
            city: {required},
            school: {required},
            pupils: {required},
            class_list: {required},
            isPersonal: {
                sameAs: sameAs(() => true)
            },
            isAgree: {
                sameAs: sameAs(() => true)
            }
        }
    },
    computed: {
        textMentorsButton() {
            return this.isShowMentors ? 'Скрыть наставников' : 'Показать наставников'
        }
    },
    methods: {
        viewMentors() {
            this.isShowMentors = !this.isShowMentors

            if (this.isShowMentors && !this.mentors) {
                Cnt.getAllMentors({ordering: 'priority'}).then(response => this.mentors = response.data.results)
            }
        },
        selectCity(val) {
            this.partner.city = val
        },
        selectCityForSchool(val) {
            this.forSchool.city = val
        },
        sendPartner() {
            this.$v.partner.$touch()
            if (this.$v.partner.$invalid) {
                console.log('Partner form is error')
            } else if (!this.isSended) {
                this.isSended = true
                let [last_name, first_name, middle_name] = this.partner.full_name.split(' ')

                Cnt.schoolProposal({
                    first_name,
                    middle_name,
                    last_name,
                    ...this.partner
                })
                    .then(() => {
                        this.modalOpen('ambsdrSuccess')
                        this.$v.partner.$reset()
                        this.isCityClear = true
                        this.partner = {
                            full_name: '',
                            city: '',
                            phone: '',
                            email: '',
                            school: ''
                        }
                        this.isPersonal = false
                        this.isAgree = false
                    })
                    .catch(error => this.modalOpen('ambsdr-2-Fail', error.response.data.error_text))
                    .finally(() => this.isSended = false)
            }
        },
        sendPartnerForSchool() {
            this.$v.forSchool.$touch()
            if (this.$v.forSchool.$invalid) {
                console.log('Partner form is error')
            } else if (!this.isSended) {
                this.isSended = true
                let [last_name, first_name, middle_name] = this.forSchool.full_name.split(' ')

                Cnt.partnerForSchoolProposal({
                    first_name,
                    middle_name,
                    last_name,
                    ...this.forSchool
                })
                    .then(() => {
                        this.modalOpen('ambsdrSuccess')
                        this.$v.forSchool.$reset()
                        this.isForSchoolCityClear = true
                        this.forSchool = {
                            full_name: '',
                            city: '',
                            phone: '',
                            email: '',
                            school: '',
                            pupils: '',
                            class_list: ''
                        }
                        this.isPersonal = false
                        this.isAgree = false
                    })
                    .catch(error => this.modalOpen('ambsdr-2-Fail', error.response.data.error_text))
                    .finally(() => this.isSended = false)
            }
        }
    }
}
</script>

<style lang="less">
@color-contest-position: #FFB400;
@color-contest-background: #000;
.contest {
    padding-bottom: 60px;

    &-cover {
        position: relative;
        color: #fff;

        &-pic {
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            &:before {
                opacity: 0.4;
                content: '';
                display: block;
                position: absolute;
                z-index: 2;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: @color-contest-background;
                @media @desktop-end {
                }
                @media @laptop-end {
                }
                @media @tablet-end {
                }
                @media @mobile-end {
                    opacity: 0.7;
                }
            }

            &:after {
                opacity: 0.7;
                content: '';
                display: block;
                position: absolute;
                z-index: 2;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(to right, @color-contest-background, fade(@color-contest-background, 0%));
                @media @mobile-end {
                    opacity: 0.2;
                }
            }
        }

        &-video {
            position: absolute;
            z-index: 2;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            &:before {
                opacity: 0.4;
                content: '';
                display: block;
                position: absolute;
                z-index: 2;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: #000;
                @media @desktop-end {
                }
                @media @laptop-end {
                }
                @media @tablet-end {
                }
                @media @mobile-end {
                    opacity: 0.7;
                }
            }

            &:after {
                opacity: 0.7;
                content: '';
                display: block;
                position: absolute;
                z-index: 2;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(to right, #000, fade(#000, 0%));
                @media @mobile-end {
                    opacity: 0.2;
                }
            }
        }

        &-content {
            position: relative;
            z-index: 10;
            padding: 90px 0;
            @media @desktop-end {
            }
            @media @laptop-end {
            }
            @media @tablet-end {
                padding: 70px 0;
            }
            @media @mobile-end {
                padding: 60px 0;
            }

            .box {
                width: 70%;
                @media @desktop-end {
                }
                @media @laptop-end {
                }
                @media @tablet-end {
                    width: 100%;
                }
                @media @mobile-end {
                }
            }

            .page-title {
                //text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4);
            }

            .text {
                //text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4);
                font-size: 21px;
                color: #fff;
                @media @desktop-end {
                }
                @media @laptop-end {
                }
                @media @tablet-end {
                }
                @media @mobile-end {
                    font-size: 18px;
                    br {
                        display: none;
                    }
                }
            }

            .breadcrumbs {
                color: #fff;

                li {
                    &:after {
                    }
                }
            }
        }

        .buttons {
            margin-top: 40px;
            @media @desktop-end {
            }
            @media @laptop-end {
            }
            @media @tablet-end {
            }
            @media @mobile-end {
                margin-bottom: -20px;
            }

            .button {
                min-width: 210px;
                margin-right: 20px;
                margin-top: 0;
                @media @desktop-end {
                }
                @media @laptop-end {
                }
                @media @tablet-end {
                }
                @media @mobile-end {
                    margin-bottom: 20px;
                }

                &:last-child {
                    margin-right: 0;
                }
            }

            .button-hollow {
                min-width: 210px;
                margin-right: 20px;
                @media @desktop-end {
                }
                @media @laptop-end {
                }
                @media @tablet-end {
                }
                @media @mobile-end {
                    margin-bottom: 20px;
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        .button {
            min-width: 200px;
            margin-top: 40px;
            @media @oldy-end {
                width: 100%;
            }
        }

        .button-hollow {
            min-width: 200px;
            background: none;
            border-color: #fff;
            color: #fff;
            @media @oldy-end {
                width: 100%;
            }

            &:focus {
                background: none;
                border-color: #fff;
                color: #fff;
            }

            &:hover {
                background: @color-orange;
                border-color: @color-orange;
                color: #fff;
            }
        }
    }

    &-info {
        display: flex;
        margin: 0 -25px 60px;

        .column {
            position: relative;
            width: 50%;
            padding: 0 25px;

            &:nth-child(1) {
            }

            &:nth-child(2) {
            }
        }

        .grid {
            margin: 0 -25px;
        }

        .box {
            padding: 0 25px;

            &:nth-child(1) {
                width: 66.66%;
                //width: 70%;
                //width: 60%;
            }

            &:nth-child(2) {
                width: 33.33%;
                //width: 30%;
                //width: 40%;
            }
        }
    }

    &-text {
        padding: 35px;
        background: #F6F7FA;
        border-radius: 6px;

        .text {
            margin-bottom: 40px;
            font-size: 17px;

            small {
                font-size: 14px;
            }
        }
    }

    &-pic {
        overflow: hidden;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 25px;
        right: 25px;
        //display: block;
        //width: 100%;
        border-radius: 6px;
    }

    &-date {
        margin-bottom: 35px;
        font-size: 18px;
    }

    &-step {
        margin-bottom: 60px;

        .text {
            margin-bottom: 30px;
        }
    }

    &-catalog {
        .grid {
            margin: 0 -30px;
        }

        .box {
            padding: 0 30px;

            &:nth-child(1) {
                width: 34%;
                @media @desktop-end {
                }
                @media @laptop-end {
                }
                @media @tablet-end {
                    width: 100%;
                }
                @media @mobile-end {
                }
            }

            &:nth-child(2) {
                width: 66%;
                @media @desktop-end {
                }
                @media @laptop-end {
                }
                @media @tablet-end {
                    width: 100%;
                }
                @media @mobile-end {
                }
            }
        }

        .pagination {
            margin-top: 20px;
            @media @desktop-end {
            }
            @media @laptop-end {
            }
            @media @tablet-end {
            }
            @media @mobile-end {
                margin-top: 30px;
            }
        }

        .contest-videos-list {
            .grid {
                margin: 0 -20px;
            }

            .box {
                width: 50%;
                padding: 0 20px;
                //&:nth-child(1){
                //    width: 50%;
                //}
                //&:nth-child(2){
                //    width: 50%;
                //}
            }
        }
    }

    &-videos {
        &-filters {
            margin-bottom: 30px;
            //padding: 20px 30px;
            //background: #F6F7FA;
            &-list {
                margin: 0 -25px;
                font-size: 0;
                @media @desktop-end {
                }
                @media @laptop-end {
                }
                @media @tablet-end {
                }
                @media @mobile-end {
                    margin: 0 -20px;
                }
            }

            .checkbox-filter {
                vertical-align: top;
                //width: 25%;
                padding: 10px 25px;
                @media @desktop-end {
                }
                @media @laptop-end {
                }
                @media @tablet-end {
                    //width: 33.33%;
                }
                @media @mobile-end {
                    //display: block;
                    //width: 100%;
                    padding: 10px 20px;
                }
                @media @oldy-end {
                    display: block;
                }

                input {
                    &:checked {
                        & + span {
                            //font-weight: 700;
                        }
                    }
                }
            }
        }

        &.top-10 {
            margin-bottom: 60px;
            padding-bottom: 20px;
            border-bottom: 1px solid #eee;

            &:last-child {
                margin-bottom: 0;
                padding-bottom: 0;
                border-bottom: none;
            }

            .box {
                .video {
                    &:after {
                        content: '1';
                        display: block;
                        position: absolute;
                        z-index: 20;
                        top: -10px;
                        right: -10px;
                        width: 28px;
                        height: 28px;
                        line-height: 28px;
                        text-align: center;
                        background: @color-contest-position;
                        border-radius: 50%;
                        font-size: 17px;
                        font-weight: 700;
                        color: @color-text !important;
                    }
                }

                .video-pic {
                    border: 2px solid @color-contest-position;
                }

                &:nth-child(1) {
                    .video {
                        &:after {
                            content: '1';
                            background: @color-contest-position;
                            //background: #FF0000;
                        }
                    }

                    .video-pic {
                        border-color: @color-contest-position;
                        //border-color: #FF0000;
                    }
                }

                &:nth-child(2) {
                    .video {
                        &:after {
                            content: '2';
                            background: lighten(@color-contest-position, 15%);
                        }
                    }

                    .video-pic {
                        border-color: lighten(@color-contest-position, 15%);
                    }
                }

                &:nth-child(3) {
                    .video {
                        &:after {
                            content: '3';
                            background: lighten(@color-contest-position, 20%);
                        }
                    }

                    .video-pic {
                        border-color: lighten(@color-contest-position, 20%);
                    }
                }

                &:nth-child(4) {
                    .video {
                        &:after {
                            content: '4';
                            background: lighten(@color-contest-position, 24%);
                        }
                    }

                    .video-pic {
                        border-color: lighten(@color-contest-position, 24%);
                    }
                }

                &:nth-child(5) {
                    .video {
                        &:after {
                            content: '5';
                            background: lighten(@color-contest-position, 28%);
                        }
                    }

                    .video-pic {
                        border-color: lighten(@color-contest-position, 28%);
                    }
                }

                &:nth-child(6) {
                    .video {
                        &:after {
                            content: '6';
                            background: lighten(@color-contest-position, 32%);
                        }
                    }

                    .video-pic {
                        border-color: lighten(@color-contest-position, 32%);
                    }
                }

                &:nth-child(7) {
                    .video {
                        &:after {
                            content: '7';
                            background: lighten(@color-contest-position, 35%);
                        }
                    }

                    .video-pic {
                        border-color: lighten(@color-contest-position, 35%);
                    }
                }

                &:nth-child(8) {
                    .video {
                        &:after {
                            content: '8';
                            background: lighten(@color-contest-position, 38%);
                        }
                    }

                    .video-pic {
                        border-color: lighten(@color-contest-position, 38%);
                    }
                }

                &:nth-child(9) {
                    .video {
                        &:after {
                            content: '9';
                            background: lighten(@color-contest-position, 41%);
                        }
                    }

                    .video-pic {
                        border-color: lighten(@color-contest-position, 41%);
                    }
                }

                &:nth-child(10) {
                    .video {
                        &:after {
                            content: '10';
                            background: lighten(@color-contest-position, 43%);
                        }
                    }

                    .video-pic {
                        border-color: lighten(@color-contest-position, 43%);
                    }
                }
            }

            .video-tag {
                display: none !important;
            }

            .button, .button-hollow {
                display: none !important;
            }
        }

        &.top-3 {
            margin-bottom: 60px;
            padding-bottom: 20px;
            border-bottom: 1px solid #eee;

            &:last-child {
                margin-bottom: 0;
                padding-bottom: 0;
                border-bottom: none;
            }

            .box {
                .video {
                    &:after {
                        content: '1';
                        display: block;
                        position: absolute;
                        z-index: 20;
                        top: -10px;
                        right: -10px;
                        width: 28px;
                        height: 28px;
                        line-height: 28px;
                        text-align: center;
                        background: @color-contest-position;
                        border-radius: 50%;
                        font-size: 17px;
                        font-weight: 700;
                        color: @color-text !important;
                    }
                }

                .video-pic {
                    border: 2px solid @color-contest-position;
                }

                &:nth-child(1) {
                    .video {
                        &:after {
                            content: '1';
                            background: @color-contest-position;
                            //background: #FF0000;
                        }
                    }

                    .video-pic {
                        border-color: @color-contest-position;
                        //border-color: #FF0000;
                    }
                }

                &:nth-child(2) {
                    .video {
                        &:after {
                            content: '2';
                            background: lighten(@color-contest-position, 20%);
                        }
                    }

                    .video-pic {
                        border-color: lighten(@color-contest-position, 20%);
                    }
                }

                &:nth-child(3) {
                    .video {
                        &:after {
                            content: '3';
                            background: lighten(@color-contest-position, 30%);
                        }
                    }

                    .video-pic {
                        border-color: lighten(@color-contest-position, 30%);
                    }
                }
            }

            .video-tag {
                display: none !important;
            }

            .button, .button-hollow {
                display: none !important;
            }
        }

        .grid {
            margin: 0 -25px;
            @media @desktop-end {
            }
            @media @laptop-end {
            }
            @media @tablet-end {
                margin: 0 -20px;
            }
            @media @mobile-end {
            }
        }

        .box {
            width: 33.33%;
            padding: 0 25px;
            @media @desktop-end {
            }
            @media @laptop-end {
            }
            @media @tablet-end {
                width: 50%;
                padding: 0 20px;
            }
            @media (max-width: 640px) {
                width: 100%;
            }
        }

        .pagination {
            margin-top: 15px;
        }
    }

    .section-title-wrap {
        @media @desktop-end {
        }
        @media @laptop-end {
        }
        @media @tablet-end {
        }
        @media @mobile-end {
            margin-bottom: 30px;
        }

        .grid {
        }

        .box {
            &:nth-child(1) {
                @media @desktop-end {
                }
                @media @laptop-end {
                }
                @media @tablet-end {
                    width: 66.66%;
                }
                @media @mobile-end {
                    width: 100%;
                }
            }

            &:nth-child(2) {
                @media @desktop-end {
                }
                @media @laptop-end {
                }
                @media @tablet-end {
                    width: 33.33%;
                }
                @media @mobile-end {
                    width: 100%;
                }
            }
        }
    }

    .buttons-list {
        & > * {
            margin-right: 20px;

            &:last-child {
                margin-right: 0;
            }
        }

        .button {
            min-width: 210px;
        }

        .button-hollow {
            min-width: 210px;
        }
    }

    .panel {
        margin-left: 0;
        margin-right: 0;
        padding: 30px 35px 35px;
        background: #F6F7FA;
    }

    .partners-slider {
        //margin-top: -10px;
        margin-top: 40px;
        margin-bottom: 0;
    }

    .partner {
        height: 85px;
        line-height: 85px;
        border: none;

        .pic {
            max-width: 80%;
            max-height: 80px;
            @media @desktop-end {
            }
            @media @laptop-end {
            }
            @media @tablet-end {
            }
            @media @mobile-end {
                max-width: 190px;
            }
        }
    }

    .contacts {
        padding-bottom: 30px;

        .grid {
            margin: 0 -15px;
        }

        .box {
            vertical-align: middle;
            //width: 33.33%;
            width: 50%;
            padding: 0 15px;

            &:nth-child(1) {
                //width: 40%;
                @media @desktop-end {
                }
                @media @laptop-end {
                }
                @media @tablet-end {
                    display: none !important;
                }
                @media @mobile-end {
                }
            }

            &:nth-child(2) {
                //width: 30%;
                @media @desktop-end {
                }
                @media @laptop-end {
                }
                @media @tablet-end {
                    width: 50%;
                }
                @media @mobile-end {
                    width: 100%;
                }
            }

            &:nth-child(3) {
                //width: 30%;
                @media @desktop-end {
                }
                @media @laptop-end {
                }
                @media @tablet-end {
                    width: 50%;
                }
                @media @mobile-end {
                    width: 100%;
                    padding-top: 20px;
                }
            }
        }
    }

    .contact {
        margin-bottom: 0;
        font-size: 16px;

        .info {
            margin-bottom: 0;
        }
    }

    .main-button-group {
        //margin: -10px 0;
    }

    .main-navigation {
        margin-top: 0;
    }

    .start-navigation-button {
        padding: 0 90px 0 25px;
        font-size: 17px;

        &:after {
            display: none !important;
        }

        &.orange {
            &:before {
                top: 50%;
                width: 46px;
                height: 46px;
                margin-top: -23px;
                margin-right: 20px;
                background: url(../../assets/img/icons/icon-youtube-white.svg) center center no-repeat;
                -webkit-background-size: contain !important;
                background-size: contain !important;
            }
        }

        &.blue {
            &:before {
                top: 50%;
                width: 46px;
                height: 46px;
                margin-top: -23px;
                margin-right: 20px;
                background: url(../../assets/img/icons/icon-rocket-white.svg) center center no-repeat;
                -webkit-background-size: contain !important;
                background-size: contain !important;
            }
        }

        &.gray {
            &:before {
                top: 50%;
                width: 46px;
                height: 46px;
                margin-top: -23px;
                margin-right: 20px;
                background: url(../../assets/img/icons/icon-sign-up-white.svg) center center no-repeat;
                -webkit-background-size: contain !important;
                background-size: contain !important;
            }
        }
    }

    .form-block {
        max-width: 480px;
        margin: auto;
        padding: 20px 0;
        box-shadow: none !important;
        border-radius: 0;

        .block-title {
            font-size: 26px;
        }

        .select-dropdown {
            max-height: 160px;
        }
    }

    .block-title {
        margin-bottom: 35px;

        &.sm {
            margin-bottom: 30px;
        }
    }

    .alert {
        margin-top: 0;
        margin-bottom: 0;

        .content {
            @media @desktop-end {
            }
            @media @laptop-end {
            }
            @media @tablet-end {
                padding-right: 30px;
            }
            @media @mobile-end {
                padding-right: 15px;
            }
        }

        .box {
            &:nth-child(1) {
                width: 70%;
                @media @desktop-end {
                }
                @media @laptop-end {
                }
                @media @tablet-end {
                    width: 60%;
                }
                @media @mobile-end {
                    width: 100%;
                }
            }

            &:nth-child(2) {
                width: 30%;
                @media @desktop-end {
                }
                @media @laptop-end {
                }
                @media @tablet-end {
                    width: 40%;
                }
                @media @mobile-end {
                    width: 100%;
                }
            }
        }

        .text {
            @media @desktop-end {
            }
            @media @laptop-end {
            }
            @media @tablet-end {
            }
            @media @mobile-end {
            }
        }

        .button {
            @media @oldy-end {
                margin-left: 0;
                width: 100%;
            }
        }
    }
}

.main-button-group {
    @media @desktop-end {
    }
    @media @laptop-end {
    }
    @media @tablet-end {
    }
    @media @mobile-end {
    }

    .grid {
        margin: 0 -25px;
        @media @desktop-end {
        }
        @media @laptop-end {
        }
        @media @tablet-end {
            margin: -10px;
        }
        @media @mobile-end {
        }
    }

    .box {
        width: 33.33%;
        padding: 0 25px;
        @media @desktop-end {
        }
        @media @laptop-end {
        }
        @media @tablet-end {
            width: 50%;
            padding: 10px;
        }
        @media @mobile-end {
            width: 100%;
        }
    }
}

.main-button {
    position: relative;
    display: block;
    width: 100%;
    height: 88px;
    line-height: 88px;
    padding: 0 90px 0 25px;
    text-align: left;
    box-shadow: 10px 10px 25px rgba(108, 109, 109, 0.2);
    border-radius: 5px;
    .font-title;
    font-size: 17px;
    color: #fff !important;
    @media @desktop-end {
    }
    @media @laptop-end {
    }
    @media @tablet-end {
    }
    @media @mobile-end {
        padding-right: 85px;
        font-size: 16px;
    }

    &.blue {
        background: @color-blue;

        &:hover {
            background: @color-blue-hover;
        }
    }

    &.orange {
        background: @color-orange;

        &:hover {
            background: @color-orange-hover;
        }
    }

    &.gray {
        background: @color-gray;

        &:hover {
            background: @color-gray-hover;
        }
    }
}

.main-button-text {
    display: inline-block;
    vertical-align: middle;
    line-height: 1.4;
}

.main-button-icon {
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    width: 40px;
    height: 40px;
    line-height: 40px;
    margin-top: -20px;
    margin-right: 20px;
    text-align: center;

    img {
        display: inline-block;
        vertical-align: middle;
        width: 100%;
        max-width: 40px;
        max-height: 40px;
    }
}

.jury-block {
    .jury-group {
        .box {
            vertical-align: top;
        }
    }

    .section-bar {
        .block-title {
            margin-bottom: 0;
            @media @desktop-end {
            }
            @media @laptop-end {
            }
            @media @tablet-end {
            }
            @media @mobile-end {
                margin-bottom: 35px;
            }
        }
    }

    .jury {
        align-items: flex-start;
    }

    .jury-group {
        padding-top: 50px;
    }

    .jury-job {
        //margin-bottom: 2px;
    }

    .link-dashed {
        //border-bottom-color: @color-main;
        //color: @color-main;
        font-size: 15px;
    }
}

.jury-group {
    padding-top: 15px;
    margin-bottom: -40px;
    // max-height: 0;
    // transition: all 2s ease;
    // &.show {
    //     max-height: none;
    // }
    .grid {
        margin: 0 -25px;
    }

    .box {
        vertical-align: middle;
        width: 50%;
        padding: 0 25px;
        @media @desktop-end {
        }
        @media @laptop-end {
        }
        @media @tablet-end {
        }
        @media @mobile-end {
            width: 100%;
        }
    }
}

.jury {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    @media @desktop-end {
    }
    @media @laptop-end {
    }
    @media @tablet-end {
    }
    @media @mobile-end {
        align-items: flex-start;
    }

    &.middle {
        @media @mobile-end {
            align-items: center;
        }
    }
}

.jury-avatar {
    flex-shrink: 0;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    margin-right: 20px;
    .cover;
}

.jury-name {
    margin-bottom: 5px;
    .font-title;
    font-size: 18px;
    font-weight: 700;
}

.jury-job {
    font-size: 16px;
    font-weight: 400;
}

.stages {
    float: right;
    width: 50%;
    padding-top: 15px;
    @media @desktop-end {
    }
    @media @laptop-end {
    }
    @media @tablet-end {
    }
    @media @mobile-end {
        width: 100%;
        padding-left: 65px;
    }

    &-block {
        overflow: hidden;
        position: relative;
        //left: -15px;
        //padding-left: 65px;
        .clear;
    }

    &-line {
        position: absolute;
        top: 15px;
        left: 50%;
        margin-left: -50px;
        width: 1px;
        height: 100%;
        background: #ddd;
        @media @desktop-end {
        }
        @media @laptop-end {
        }
        @media @tablet-end {
        }
        @media @mobile-end {
            left: 0;
            margin-left: 15px;
        }
    }
}

.nomination-group {
    margin-bottom: -35px;
    @media @desktop-end {
    }
    @media @laptop-end {
    }
    @media @tablet-end {
        margin-bottom: -15px;
    }
    @media @mobile-end {
    }

    .grid {
        margin: 0 -25px;
    }

    .box {
        width: 50%;
        padding: 0 25px;
        @media @desktop-end {
        }
        @media @laptop-end {
        }
        @media @tablet-end {
            width: 100%;
        }
        @media @mobile-end {
        }
    }
}

.nomination-wrap {
    padding-bottom: 65px;
    @media @desktop-end {
    }
    @media @laptop-end {
    }
    @media @tablet-end {
    }
    @media @mobile-end {
        padding-bottom: 50px;
    }

    &:last-child {
        padding-bottom: 0;
        @media @desktop-end {
        }
        @media @laptop-end {
        }
        @media @tablet-end {
        }
        @media @mobile-end {
            padding-bottom: 0;
        }
    }

    .nomination-item {
        padding: 0;
        @media @desktop-end {
        }
        @media @laptop-end {
        }
        @media @tablet-end {
        }
        @media @mobile-end {
            padding: 0;
        }
    }
}

.nomination-item {
    display: flex;
    align-items: center;
    padding: 25px 0 35px;
    @media @desktop-end {
    }
    @media @laptop-end {
    }
    @media @tablet-end {
        //align-items: center;
        padding: 15px 0;
    }
    @media @mobile-end {
        display: block;
        margin-bottom: 20px;
    }

    &.top {
        align-items: flex-start;
    }
}

.nomination-pic {
    flex-shrink: 0;
    position: relative;
    top: 0;
    max-width: 130px;
    max-height: 130px;
    margin-right: 30px;
    @media @desktop-end {
    }
    @media @laptop-end {
    }
    @media @tablet-end {
    }
    @media @mobile-end {
        top: 0;
        display: block;
        margin-bottom: 15px;
        margin-right: 0;
    }
}

.nomination-text {
    font-size: 17px;

    &.text {
        //font-size: 16px;
    }
}

.section-simple {
    &.light {
        .faq-item {
            border-bottom-color: #e7e7e7;
        }
    }
}

.faq-group {
}

.faq-item {
    display: block;
    border-bottom: 1px solid #eaeaea;
    .transition;

    &:last-child {
        border-bottom: none;
    }

    &.active {
        .faq-header {
            &:after {
                .rotate(180deg);
            }
        }

        .faq-title {
            color: @color-main;
        }
    }

    .text {
        font-size: 16px;

        &.md {
            font-size: 16px;
        }
    }
}

.faq-checkbox {
    display: none;
}

.faq-header {
    position: relative;
    padding: 20px 50px 20px 0;
    cursor: pointer;

    &:after {
        content: '';
        display: block;
        position: absolute;
        top: 20px;
        right: 0;
        width: 25px;
        height: 25px;
        background: url(../../assets/img/icons/icon-select.svg) center center no-repeat;
        .transition;
    }

    &:hover {
        .faq-title {
            color: @color-main;
        }
    }
}

.faq-title {
    font-size: 17px;
    .transition;
}

.faq-body {
    display: none;
    padding-bottom: 25px;

    &.show {
        display: block;
    }
}

.contacts-simple {
    //display: flex;
    //flex-wrap: nowrap;
    //align-items: center;
    //justify-content: space-between;
    margin-bottom: -20px;
    font-size: 16px;
}

.contact-simple {
    display: inline-block;
    margin-right: 90px;
    margin-bottom: 20px;
    @media @desktop-end {
    }
    @media @laptop-end {
    }
    @media @tablet-end {
        width: 45%;
        margin-right: 0;
    }
    @media @mobile-end {
        width: 100%;
    }

    &:last-child {
        margin-right: 0;
        @media @desktop-end {
        }
        @media @laptop-end {
        }
        @media @tablet-end {
        }
        @media @mobile-end {
            margin-bottom: 0;
        }
    }

    .info {
        margin-bottom: 5px;
    }

    .social-contact-group {
        margin-left: -10px;
        margin-right: 0 !important;
    }

    ul, ol {
        li {
            position: relative;

            & + li {
                margin-top: 8px;
            }
        }
    }

    ol {
        li {
            margin-left: 22px;
            padding-left: 7px;
            list-style-type: decimal;
        }
    }

    ul {
        li {
            padding-left: 22px;
            @media @laptop-end {
            }
            @media @tablet-end {
            }
            @media @mobile-end {
            }

            &:before {
                content: '';
                display: block;
                position: absolute;
                top: 10px;
                left: 0;
                width: 16px;
                height: 1px;
                background: @color-light;
                @media @laptop-end {
                }
                @media @tablet-end {
                }
                @media @mobile-end {
                }
            }
        }
    }
}

.contest-form-block {
    .grid {
        margin: 0 -15px;
    }

    .box {
        width: 50%;
        padding: 0 15px;
        @media @desktop-end {
        }
        @media @laptop-end {
        }
        @media @tablet-end {
        }
        @media @mobile-end {
            width: 100%;
        }
    }

    .button {
        min-width: 240px;
        @media @desktop-end {
        }
        @media @laptop-end {
        }
        @media @tablet-end {
        }
        @media @mobile-end {
            width: 100%;
            min-width: 0;
        }
    }
}

.about-block {
    .main-button {
        display: inline-block;
        width: 270px;
        height: 68px;
        line-height: 68px;
        font-size: 16px;
    }

    .main-button-text {
        position: relative;
        top: -2px;
        line-height: 1.3;
    }

    .main-button-icon {
        img {
            max-width: 35px;
            max-height: 35px;
        }
    }
}

.contest {
    .partner-program-block {
        @media @desktop-end {
        }
        @media @laptop-end {
        }
        @media @tablet-end {
        }
        @media @mobile-end {
            text-align: center;
        }

        .block-title {
            margin-bottom: 0;
        }

        .section-bar {
            .box {
                vertical-align: middle;

                &:nth-child(1) {
                }

                &:nth-child(2) {
                    @media @desktop-end {
                    }
                    @media @laptop-end {
                    }
                    @media @tablet-end {
                    }
                    @media @mobile-end {
                        text-align: center;
                    }
                }
            }
        }
    }
}

.partner-program-logo {
    display: inline-block;
    width: 200px;
    @media @desktop-end {
    }
    @media @laptop-end {
    }
    @media @tablet-end {
    }
    @media @mobile-end {
        width: 190px;
        margin-top: 35px;
    }
}
</style>
